<template>
  <div class="myFocus">
    <header>我的关注</header>
    <div class="min400" v-loading="loading">
      <div v-if="!loading && !focusList.length" class="null-box">
        <img
          :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
          alt=""
          srcset=""
        />
        <div class="title">暂无数据</div>
      </div>
      <div class="content" v-for="(item, index) in focusList" :key="index">
        <div class="list">
          <img
            v-if="item.doctor"
            @click="goUrl(item.doctor.user_id)"
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
            alt="头像"
          />
          <div
            v-if="item.doctor"
            @click="goUrl(item.doctor.user_id)"
            class="user"
          >
            <span class="name">{{ item.doctor.name }}</span>
            <span class="remark">粉丝数：{{ item.follow_num }}</span>
          </div>
          <el-button @click="attention(index, true)" round class="active"
            >已关注</el-button
          >
        </div>
      </div>
      <div class="paging">
        <el-pagination
          hide-on-single-page
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="per_page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      focusList: [],
      loading: false,
      total: 0,
      per_page: 10,
      page: 1,
    };
  },
  activated() {
    this.getList();
  },
  methods: {
    async attention(i, bol) {
      //关注
      let item = this.focusList[i];
      // this.xin.LoaDing();
      let { code, message } = await this.$userApi.postDiscoverAttention({
        attention_id: item.doctor.id,
      });
      // uni.hideLoading()
      if (code == 200) {
        if (bol) {
          this.xin.message("已取消关注");
          this.focusList.splice(i, 1);
        } else {
          if (item.attention) this.xin.message("已取消关注");
          else this.xin.message("关注成功!");
          item.attention = !item.attention;
          this.$set(this.focusList, i, item);
        }
      } else {
        this.$message.error(message);
      }
    },
    async getList() {
      let { page, total } = this;
      this.loading = true;
      let { code, data, message } = await this.$userApi.getPersonalUser(
        { page },
        2
      );
      this.loading = false;
      if (code === 200) {
        this.focusList = data.data;
        this.total = data.total;
        this.per_page = data.per_page;
      } else {
        this.$message.error(message);
      }
    },
    handleSizeChange(val) {
      val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },

    // 页面跳转
    goUrl(id) {
      if (id != 0) {
        this.xin.goUrl("/found-personal?id=" + id);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.myFocus {
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
  }
  .content {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f2f2f2;
    > .list {
      box-sizing: border-box;
      padding: 30px 0;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      &:last-child {
        border: none;
      }
      > .avatar {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        object-fit: cover;
        cursor: pointer;
      }
      > .user {
        cursor: pointer;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        > .name {
          font-size: 24px;
          color: #333333;
        }
        > .remark {
          margin-top: 10px;
          font-size: 18px;
          color: #8f8f8f;
        }
      }
      > .el-button {
        background-color: white;
        margin-left: auto;
        border-radius: 5px;
        border-color: #8f8f8f;
        color: #8f8f8f;
        font-size: 20px;
        &.active {
          opacity: 1;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: left;
          color: #8f8f8f;
        }
      }
    }
  }
}
</style>
