<template>
  <div class="page">
    <section>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/personalCenter' }">
          个人中心
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{
          navList[ComponentsIndex].label
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <section>
        <nav>
          <el-menu
            :default-active="ComponentsIndex"
            active-text-color="#03DBC6"
            @select="ComponentsIndex = $event"
          >
            <template v-for="(navItem, navIndex) in navList">
              <el-submenu
                v-if="navItem.childList"
                :key="navIndex"
                :index="`${navIndex}`"
              >
                <template slot="title">
                  <span>{{ navItem.label }}</span>
                </template>
                <template
                  v-for="(childNavItem, childNavIndex) in navItem.childList"
                >
                  <el-menu-item
                    :key="childNavIndex"
                    :index="`${navIndex}-${childNavIndex}`"
                    >{{ childNavItem.label }}</el-menu-item
                  >
                </template>
              </el-submenu>
              <el-menu-item v-else :key="navIndex" :index="`${navIndex}`">
                <span slot="title">{{ navItem.label }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </nav>
        <div class="content">
          <keep-alive>
            <component :is="personalCenterComponents"></component>
          </keep-alive>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

// 批量引入components/setting目录下的所有组件
const components = require
  .context("@components/userCenter/", false, /.*\.vue/)
  .keys()
  .reduce((components, componentsPath) => {
    const componentsName = componentsPath.replace(/\.\/(.*)\.vue/, "$1");
    components[componentsName] =
      require(`@components/userCenter/${componentsName}`).default;
    return components;
  }, {});

export default {
  components,
  data() {
    return {
      navList: [
        { label: "我的收藏", components: "myCollection" },
        { label: "我的评论", components: "myComment" },
        { label: "我的点赞", components: "myLike" },
        { label: "历史", components: "browsingHistory" },
        { label: "我的粉丝", components: "myFans" },
        { label: "我的关注", components: "myFocus" },
      ],
      ComponentsIndex: "0",
    };
  },
  watch: {
    ComponentsIndex(val) {
      // this.$route.query.index = val;
      const newUrl = `#/userCenter?index=${val}`;
      window.history.replaceState("", "", newUrl);
    },
    $route(val) {
      if (val.query.index) this.ComponentsIndex = val.query.index;
    },
  },
  created() {
    this.ComponentsIndex = this.$route.query.index ?? "0";
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    personalCenterComponents() {
      return this.navList[this.ComponentsIndex.split("-")[0]].childList
        ? this.navList[this.ComponentsIndex.split("-")[0]].childList[
            this.ComponentsIndex.split("-")[1]
          ].components
        : this.navList[this.ComponentsIndex.split("-")[0]].components;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f2f2f2;
  > section {
    > section {
      margin-top: 30px;
      display: flex;
      > nav {
        width: 196px;
        height: max-content;
        background-color: white;
        position: sticky;
        top: 100px;
        ::v-deep {
          .el-menu {
            .el-menu--inline {
              overflow: hidden;
              .el-menu-item {
                padding-left: 50px !important;
              }
            }
            .el-menu-item,
            .el-submenu {
              span {
                margin-left: 10px;
              }
            }
          }
          .is-active {
            &::before {
              position: absolute;
              left: 0;
              top: calc((100% - 35px) / 2);
              content: "";
              display: block;
              width: 5px;
              height: 35px;
              background-color: $--themeColor;
            }
          }
        }
      }
      > .content {
        flex: 1;
        margin-left: 20px;
        // background-color: white;
        height: max-content;
      }
    }
  }
}
</style>
