<template >
  <!-- 药品库 -->
  <div v-if="platform && item.data.title!='内容已下架'">
    <!-- 评论 -->
    <div v-if="myType == 2">
      <div v-if="item.data" class="comment-box">
        <div class="comm-top">
          <img
            @click.stop="
              xin.goUrl('/found-personal?id=' + userInfo.info.user_id)
            "
            :src="userInfo.avatar"
            alt=""
          />
          <div
            @click.stop="
              xin.goUrl('/found-personal?id=' + userInfo.info.user_id)
            "
            class="comm-msg"
          >
            <div class="name">{{ userInfo.nickname }}</div>
            <div class="time">{{ item.create_time | timeFormat }}</div>
          </div>
          <div @click.stop="singleDelete(item)" class="delete-comm">
            删除评论
          </div>
        </div>
        <div class="content sheng">{{ item.content }}</div>

        <div
          class="article-content"
          style="cursor: pointer"
          @click="jump(item.data.id)"
        >
          药品名称: {{ item.data.common_name }}
        </div>
        <div class="numerical">
          <span>{{ item.data.praised }}赞</span> <span>·</span>
          <span>{{ item.data.comments }}评论</span>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="group-one-right2" @click="jump(item.id)">
        药品名称: {{ item.common_name }}
        <div class="data" style="margin-top: 20px">
          <span>{{ item.praised }}赞</span>
          <span>·</span>
          <span>{{ item.comments }}评论</span>
          <span>·</span>
          <span>{{ item.browse | pageviews }} 浏览</span>
          <span>·</span>
          <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
          <span class="colse-collect" @click.stop="singleDelete(item)">
            {{ myType | colseName }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <!-- 评论 -->
    <div v-if="myType == 2">
      <div v-if="item.data && item.data.is_show" class="comment-box">
        <div v-if="item.data.doctor" class="comm-top">
          <img
            @click.stop="
              xin.goUrl('/found-personal?id=' + userInfo.info.user_id)
            "
            :src="userInfo.avatar"
            alt=""
          />
          <div
            @click.stop="
              xin.goUrl('/found-personal?id=' + userInfo.info.user_id)
            "
            class="comm-msg"
          >
            <div class="name">{{ userInfo.nickname }}</div>
            <div class="time">{{ item.create_time | timeFormat }}</div>
          </div>
          <div @click.stop="singleDelete(item)" class="delete-comm">
            删除评论
          </div>
        </div>
        <div class="content sheng">{{ item.content }}</div>

        <div class="article-content">
          <list :discoveryList="[item.data]"></list>
        </div>
        <div class="numerical">
          <span>{{ item.data.praise_num }}赞</span> <span>·</span>
          <span>{{ item.data.comment_num }}评论</span>
        </div>
      </div>
    </div>

    <div v-else>
      <!-- 医学视频 -->
      <div
        class="group-one"
        v-if="isType == 3"
        @click="
          $router.push({
            path:
              '/index-syllabus-videoLectureDetail?video_uid=' + item.video_uid,
          })
        "
      >
        <div class="group-one-box">
          <img class="banner" :src="item.avatar" />
          <div class="rectangular">
            <img src="@image/bofang.png" />
          </div>
        </div>
        <div class="group-one-right">
          <div class="subTitle" v-html="item.video_name"></div>
          <div class="data">
            <span>{{ item.add_time | timeFormat("yyyy-mm-dd") }}</span>
            <span class="colse-collect" @click.stop="singleDelete(item)">{{
              myType | colseName
            }}</span>
          </div>
        </div>
      </div>
      <!-- 新闻 -->
      <div
        class="group-one-right2 group-one"
        v-if="item.new_type"
        @click="$router.push({ path: 'index-newsDetail?id=' + item.id })"
      >
        <span v-html="item.title" class="subTitle widwd"></span>
        <span
          v-html="item.subtitle"
          class="subTitle widwd"
          style="font-size: 20px; color: #333"
        ></span>

        <img
          v-if="
            item.images != null &&
            item.images != 'null' &&
            item.images != '' &&
            item.images != undefined &&
            item.images != 'undefined'
          "
          :src="item.images"
          style="width: 100%; height: 400px; margin-bottom: 30px"
        />

        <div class="data">
          <span>{{ item.praise_num }}赞</span>
          <span>·</span>
          <span>{{ item.comment_num }}评论</span>
          <span>·</span>
          <span>{{ item.browse_num | pageviews }}浏览</span>
          <span>·</span>
          <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
          <span class="colse-collect" @click.stop="singleDelete(item)">{{
            myType | colseName
          }}</span>
        </div>
      </div>
      <!-- 发布说说 -->
      <div class="group-one" v-else-if="item.type == 1" @click="goType(item)">
        <div v-if="item.images.length" class="group-one-box2">
          <img class="banner2" :src="item.images[0]" />
          <div class="rectangular2">
            <span v-if="item.images">{{ item.images.length }}图</span>
          </div>
        </div>
        <div class="group-one-right">
          <div class="group-one-top" v-if="item.doctor">
            <img
              class="avatar"
              @click.stop="
                xin.goUrl('/found-personal?id=' + item.doctor.user_id)
              "
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
            />
            <div
              class="group-one-top-right"
              @click.stop="$router.push({ path: '/found-personal' })"
            >
              <div class="one">
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="title"
                  >{{ item.doctor.name }}</span
                >
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="position"
                  >{{ item.doctor.title_name }}</span
                >
                <template v-if="item.doctor.user_id != userInfo.id">
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <span
            v-html="item.content"
            class="subTitle"
            :class="{ line: item.images.length }"
          ></span>
          <div v-if="isType == 2" class="data">
            <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
            <span @click.stop="singleDelete(item)" class="colse-collect">{{
              myType | colseName
            }}</span>
          </div>
          <div v-else class="data">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>

            <span v-if="item.doctor && item.doctor.user_id"
              >{{ item.comment_num }}评论</span
            >
            <span v-if="item.doctor && item.doctor.user_id">·</span>

            <span>{{ item.browse_num | pageviews }}浏览</span>
            <span>·</span>
            <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
            <span @click.stop="singleDelete(item)" class="colse-collect">{{
              myType | colseName
            }}</span>
          </div>
        </div>
      </div>
      <!-- 病例分享 -->
      <div v-if="item.type == 2 && item.doctor && item.doctor.user_id">
        <div
          class="group-one-right2"
          v-if="item.is_jc_video == 1"
          @click="
            $router.push({
              path:
                '/index-syllabus-videoLectureDetail?video_uid=' +
                item.video_uid,
            })
          "
        >
          <div class="group-one-top" v-if="item.doctor">
            <img
              @click.stop="
                xin.goUrl('/found-personal?id=' + item.doctor.user_id)
              "
              class="avatar"
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
            />
            <div class="group-one-top-right">
              <div class="one">
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="title"
                  >{{ item.doctor.name }}</span
                >
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="position"
                  >{{ item.doctor.title_name }}</span
                >
                <template v-if="item.doctor.user_id != userInfo.id">
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <span v-html="item.content" class="subTitle"></span>
          <div class="data">
            <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
            <span class="colse-collect" @click.stop="singleDelete(item)">{{
              myType | colseName
            }}</span>
          </div>
        </div>

        <div
          class="group-one-right2"
          v-else
          @click="goDetail(item.status_type, item.id)"
        >
          <div class="group-one-top" v-if="item.doctor">
            <img
              @click.stop="
                xin.goUrl('/found-personal?id=' + item.doctor.user_id)
              "
              class="avatar"
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
            />
            <div class="group-one-top-right">
              <div class="one">
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="title"
                  >{{ item.doctor.name }}</span
                >
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="position"
                  >{{ item.doctor.title_name }}</span
                >
                <template v-if="item.doctor.user_id != userInfo.id">
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <span v-html="item.content" class="subTitle"></span>
          <div class="data">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>
            <span>{{ item.comment_num }}评论</span>
            <span>·</span>
            <span>{{ item.browse_num | pageviews }}浏览</span>
            <span>·</span>
            <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
            <span class="colse-collect" @click.stop="singleDelete(item)">{{
              myType | colseName
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="group-one-right2"
        v-if="item.type == 2 && item.business"
        @click="$router.push({ path: '/index-asdvertising?is_adv=' + item.id })"
      >
        <div class="group-one-top" v-if="item.doctor">
          <img
            @click.stop="xin.goUrl('/found-personal?id=' + item.doctor.user_id)"
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
          />
          <div class="group-one-top-right">
            <div class="one">
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="title"
                >{{ item.doctor.name }}</span
              >
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="position"
                >{{ item.doctor.title_name }}</span
              >
              <template v-if="item.doctor.user_id != userInfo.id">
                <span
                  v-if="item.attention"
                  @click.stop="postDiscoverAttention(item)"
                  class="button2"
                  >已关注</span
                >
                <span
                  @click.stop="postDiscoverAttention(item)"
                  v-else
                  class="button"
                  >+关注</span
                >
              </template>
            </div>
            <span class="address"
              >{{ item.doctor.company }} {{ item.doctor.department_name }}</span
            >
          </div>
        </div>
        <span v-html="item.content" class="subTitle"></span>
        <div class="data">
          <span>{{ item.praise_num }}赞</span>
          <span>·</span>
          <!-- <span>{{ item.comment_num }}评论</span> -->
          <!-- <span>·</span> -->
          <span>{{ item.browse_num | pageviews }}浏览</span>
          <span>·</span>
          <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
          <span class="colse-collect" @click.stop="singleDelete(item)">{{
            myType | colseName
          }}</span>
        </div>
      </div>
      <!-- 学术视频 -->
      <div
        class="group-one"
        v-if="isType != 2 && item.type == 3"
        @click="
          $router.push({ path: '/found-details-videoDetails?id=' + item.id })
        "
      >
        <div class="group-one-box">
          <img class="banner" :src="item.video_img" />
          <div class="rectangular">
            <img src="@image/bofang.png" />
          </div>
        </div>
        <div class="group-one-right">
          <div class="group-one-top" v-if="item.doctor">
            <img
              class="avatar"
              @click.stop="
                xin.goUrl('/found-personal?id=' + item.doctor.user_id)
              "
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
            />
            <div
              class="group-one-top-right"
              @click="$router.push({ path: '/found-personal?id=' + item.id })"
            >
              <div class="one">
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="title"
                  >{{ item.doctor.name }}</span
                >
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="position"
                  >{{ item.doctor.title_name }}</span
                >
                <template v-if="item.doctor.user_id != userInfo.id">
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <div class="subTitle" v-html="item.title"></div>
          <div
            style="
              overflow: hidden;
              height: 32px;
              line-height: 32px;
              width: 100%;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              color: #8f8f8f;
              font-szie: 16px;
            "
          >
            {{ item.content }}
          </div>
          <div class="data">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>
            <span>{{ item.comment_num }}评论</span>
            <span>·</span>
            <span>{{ item.browse_num | pageviews }} 浏览</span>
            <span>·</span>
            <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
            <span class="colse-collect" @click.stop="singleDelete(item)">{{
              myType | colseName
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="group-one"
        v-if="isType == 2 && item.type == 3"
        @click="
          $router.push({ path: '/index-newsDetail?id=' + item.comment_user_id })
        "
      >
        <div class="group-one-right">
          <div class="group-one-top" v-if="item.doctor">
            <img
              class="avatar"
              @click.stop="
                xin.goUrl('/found-personal?id=' + item.doctor.user_id)
              "
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
            />
            <div
              class="group-one-top-right"
              @click="$router.push({ path: '/found-personal?id=' + item.id })"
            >
              <div class="one">
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="title"
                  >{{ item.doctor.name }}</span
                >
                <span
                  @click.stop="
                    xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                  "
                  class="position"
                  >{{ item.doctor.title_name }}</span
                >
                <template v-if="item.doctor.user_id != userInfo.id">
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <div class="subTitle" v-html="item.content"></div>
          <div class="data">
            <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
            <span class="colse-collect" @click.stop="singleDelete(item)">{{
              myType | colseName
            }}</span>
          </div>
        </div>
      </div>

      <!-- 指南解析 -->
      <div
        class="group-one-right2"
        v-if="item.type == 4"
        @click="goDetail(item.status_type, item.id)"
      >
        <div class="group-one-top" v-if="item.doctor">
          <img
            @click.stop="xin.goUrl('/found-personal?id=' + item.doctor.user_id)"
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
          />
          <div class="group-one-top-right">
            <div class="one">
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="title"
                >{{ item.doctor.name }}</span
              >
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="position"
                >{{ item.doctor.title_name }}</span
              >
              <template v-if="item.doctor.user_id != userInfo.id">
                <span
                  v-if="item.attention"
                  @click.stop="postDiscoverAttention(item)"
                  class="button2"
                  >已关注</span
                >
                <span
                  @click.stop="postDiscoverAttention(item)"
                  v-else
                  class="button"
                  >+关注</span
                >
              </template>
            </div>
            <span class="address"
              >{{ item.doctor.company }} {{ item.doctor.department_name }}</span
            >
          </div>
        </div>
        <span class="subTitle" v-html="item.title"></span>
        <span class="subTitle2" v-html="item.content"></span>
        <div class="group">
          <span>疾病名称：</span>
          <span>{{ item.disease }}</span>
        </div>
        <div class="group2">
          <span>搜索词：</span>
          <span>{{ item.search }}</span>
        </div>
        <div class="data">
          <span>{{ item.praise_num }}赞</span>
          <span>·</span>
          <span>{{ item.comment_num }}评论</span>
          <span>·</span>
          <span>{{ item.browse_num | pageviews }}浏览</span>
          <span>·</span>
          <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
          <span class="colse-collect" @click.stop="singleDelete(item)">{{
            myType | colseName
          }}</span>
        </div>
      </div>

      <!-- 发布问答 -->
      <div
        class="group-two"
        v-if="item.type == 5"
        @click="$router.push({ path: '/found-qa?id=' + item.id })"
      >
        <span class="title">{{ item.title }}</span>
        <div class="bottom">
          <div class="banner3" v-if="item.images.length">
            <img :src="item.images[0]" />
            <div class="banner3-icon">{{ item.images.length }}图</div>
          </div>
          <div class="group-two-div">
            <span class="subTitle" v-html="item.content"></span>
            <div class="group-two-bottom">
              <img
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="avatar"
                :src="
                  item.doctor.photo
                    ? item.doctor.photo
                    : require('@image/default.png')
                "
              />
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                >{{ item.doctor.name }}</span
              >
              <span>·</span>
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                >{{ item.doctor.title_name }}</span
              >
              <span>·</span>
              <span>{{ item.browse_num | pageviews }}浏览</span>
              <span>·</span>
              <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
              <span class="colse-collect" @click.stop="singleDelete(item)">{{
                myType | colseName
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 发布论文 -->
      <div
        class="group-one-right2"
        v-if="item.type == 11"
        @click="goDetail(item.status_type, item.id)"
      >
        <div class="group-one-top" v-if="item.doctor">
          <img
            @click.stop="xin.goUrl('/found-personal?id=' + item.doctor.user_id)"
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
          />
          <div class="group-one-top-right">
            <div class="one">
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="title"
                >{{ item.doctor.name }}</span
              >
              <span
                @click.stop="
                  xin.goUrl('/found-personal?id=' + item.doctor.user_id)
                "
                class="position"
                >{{ item.doctor.title_name }}</span
              >
              <template v-if="item.doctor.user_id != userInfo.id">
                <span
                  v-if="item.attention"
                  @click.stop="postDiscoverAttention(item)"
                  class="button2"
                  >已关注</span
                >
                <span
                  @click.stop="postDiscoverAttention(item)"
                  v-else
                  class="button"
                  >+关注</span
                >
              </template>
            </div>
            <span class="address"
              >{{ item.doctor.company }} {{ item.doctor.department_name }}</span
            >
          </div>
        </div>
        <span class="subTitle" v-html="item.title"></span>
        <span class="subTitle2" v-html="item.content"></span>

        <div class="data">
          <span>{{ item.praise_num }}赞</span>
          <span>·</span>
          <span>{{ item.comment_num }}评论</span>
          <span>·</span>
          <span>{{ item.browse_num | pageviews }}浏览</span>
          <span>·</span>
          <span>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</span>
          <span class="colse-collect" @click.stop="singleDelete(item)">{{
            myType | colseName
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@components/common/list";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    list,
  },
  data() {
    return {
      islod: true,
      isBtn: false,
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  filters: {
    //类型 ：1收藏	2评论	3点赞	4历史
    colseName(val) {
      switch (val) {
        case 1:
          val = "删除收藏";
          break;
        case 2:
          val = "取消评论";
          break;
        case 3:
          val = "取消点赞";
          break;
        case 4:
          val = "删除浏览";
          break;
      }
      return val;
    },
  },
  props: {
    isType: {
      //1文章	 2评论	3视频
      type: [Number, String],
      default: 1,
    },
    myType: {
      //我的类型
      type: [Number, String],
      default: 0,
    },
    item: {
      type: Object,
      default() {
        return {
          doctor: {},
          data: {},
        };
      },
    },
    id: {
      type: [Number, String],
      default: 0,
    },
    platform: {
      type: [Number, String],
      default: 0,
    },
  },
  mounted() {},
  methods: {
    ...mapActions("userManagement", ["getPersonal"]),
    jump(id) {
      let url;
      url = "/drugsDetail?id=" + id;
      this.xin.goUrl(url);
    },
    // 页面跳转
    goView() {
      if (!this.creation && this.item.status == 1) {
        this.$message({
          message: "正在审核中",
          type: "error",
        });
      } else if (!this.creation && this.item.status == 3) {
        this.$message({
          message: "审核未通过，请重新发布或编辑",
          type: "error",
        });
      } else {
        let url;
        if (this.item.new_type) {
          //新闻
          url = "/index-newsDetail?id=" + this.item.id;
        } else if (this.item.type == 3) {
          // 视频

          url = "/found-details-videoDetails?id=" + this.item.id;
        } else if (this.item.type == 5) {
          // 问答

          url = "/found-qa?id=" + this.item.id;
        } else {
          url = "/found-details-details?id=" + this.item.id;
        }
        this.xin.goUrl(url);
      }
    },
    goType(item) {
      if (item.doctor && item.doctor.user_id) {
        let id = item.doctor.user_id;
        if (this.isType == 2) {
          //评论
          id = item.discover_id;
        }
        if (item.status_type) {
          if (item.type == 1) {
            //说说
            if (item.is_fx_video) {
              this.xin.goUrl("/found-details-videoDetails?id=" + id);
            } else {
              if (item.discover_id) {
                this.xin.goUrl("/found-details-details?id=" + id);
              } else {
                this.xin.goUrl("/found-details-details?id=" + item.id);
              }
            }
          }
        } else {
          this.$message({
            message: "该内容已下架",
            type: "success",
          });
        }
      } else {
        if (this.isType == 2) {
          //评论
        }
        if (item.type == 1) {
          //说说
          this.xin.goUrl("/index-asdvertising?is_adv=" + item.id);
        }
      }
    },
    goDetail(status_type, id) {
      if (status_type) {
        this.$router.push({ path: "/found-details-details?id=" + id });
      } else {
        this.$message({
          message: "该内容已下架",
          type: "success",
        });
      }
    },
    async singleDelete() {
      //单个删除
      let msg = "删除";
      switch (this.myType) {
        case 1:
          msg = "取消收藏";
          break;
        case 2:
          msg = "取消评论";
          break;
        case 3:
          msg = "取消点赞";
          break;
        case 4:
          msg = "删除浏览";
          break;
      }
      this.$confirm(`确定要${msg}吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // type: 'error'
      })
        .then(async () => {
          if (this.isBtn) return;
          this.isBtn = true;
          var { code, message } = await this.$userApi.postPersonalDelColl({
            type: this.myType,
            ids: `[${this.id}]`,
          });
          this.isBtn = false;
          if (code == 200) {
            this.$emit("updateItem", {
              doctor: {},
              data: {},
              id: this.id,
            });
            this.xin.message("删除成功!");
          } else {
            this.$message.error(message);
          }
        })
        .catch((e) => e);
    },
    // 关注
    async postDiscoverAttention(item) {
      // 防止多次发送请求
      if (this.islod) {
        this.islod = false;
        let res = await this.$userApi.postDiscoverAttention({
          attention_id: item.user_id,
        });
        if (res.code == 200) {
          this.getPersonal();
          if (item.attention) {
            this.$message({
              message: "已取消关注",
              type: "success",
            });
          } else {
            this.$message({
              message: "关注成功",
              type: "success",
            });
          }
          // item.attention = !item.attention
          this.$parent.doctorListFn(item);
        } else {
          this.$message.error(res.message);
        }
        this.islod = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.comment-box {
  padding-top: 30px;

  &:hover .comm-top .delete-comm {
    display: block;
  }

  .comm-top {
    display: flex;
    padding: 0 20px;

    > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
    }

    .comm-msg {
      margin-left: 20px;
      line-height: 25px;
      cursor: pointer;

      .name {
        font-size: 18px;
        color: #333333;
        font-weight: 700;
      }

      .time {
        // margin-top: 6px;
        font-weight: 400;
        color: #8f8f8f;
      }
    }

    .delete-comm {
      display: none;
      font-weight: 500;
      margin-left: auto;
      color: #8f8f8f;
      cursor: pointer;
      line-height: 50px;
    }
  }

  .content {
    padding: 0 20px;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 50px;
    // margin: 10px 0;
  }

  .article-content {
    background: #f7f7f7;
    padding: 20px;

    /deep/.group-one {
      margin: 0px;
      padding: 0px;
      border: none;
    }
  }

  .numerical {
    margin: 0 20px;
    margin-top: 10px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 30px;

    span {
      font-size: 18px;
      font-weight: 500;
      color: #8f8f8f;
      line-height: 40px;
      margin-right: 10px;
    }
  }
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.group-one {
  display: flex;
  margin-top: 23px;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  margin-bottom: 30px;
  width: 890px;

  &:hover .data .colse-collect {
    display: inline;
  }

  > .group-one-box2 {
    position: relative;
    margin-right: 20px;

    > .rectangular2 {
      position: absolute;
      width: 50px;
      height: 26px;
      opacity: 0.3;
      background: #000000;
      border-radius: 13px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 26px;
      right: 10px;
      bottom: 10px;
    }

    > .banner2 {
      width: 270px;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
    }

    > .banner5 {
      width: 270px;
      height: 223px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  > .group-one-box {
    width: 310px;
    position: relative;
    margin-right: 20px;

    > .rectangular {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      opacity: 0.7;
      background: #000000;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      font-size: 12px;
      color: #ffffff;

      > img {
        width: 16px;
        height: 18px;
        object-fit: cover;
      }
    }

    > .banner {
      width: 310px;
      height: 182px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  > .group-one-right {
    cursor: pointer;
    flex: 1;
    border: none;
    margin-bottom: 0;

    > .subTitle2 {
      font-size: 18px;
      color: #8f8f8f;
      line-height: 1.8;
      margin-bottom: 10px;
      overflow: hidden;
      /*必须结合的属性,当内容溢出元素框时发生的事情*/
      text-overflow: ellipsis;
      /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
      display: -webkit-box;
      /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
      -webkit-line-clamp: 2;
      /*用来限制在一个块元素显示的文本的行数。*/
      -webkit-box-orient: vertical;
    }

    > .group-one-top {
      display: flex;
      align-items: center;
      position: relative;

      .group-one-top-right {
        flex: 1;
        display: flex;
        flex-direction: column;

        > .one {
          display: flex;
          flex-direction: row;
          align-items: center;

          > .title {
            font-size: 24px;
            color: #333333;
            cursor: pointer;
          }

          > .position {
            cursor: pointer;
            font-size: 13px;
            color: $--themeColor;
            height: 25px;
            border: 1px solid $--themeColor;
            border-radius: 6px;
            text-align: center;
            line-height: 25px;
            margin-left: 12px;
            padding: 0 13px;
            box-sizing: border-box;
          }

          > .button {
            position: absolute;
            right: 0;
            width: 56px;
            height: 25px;
            background: $--themeColor;
            border-radius: 5px;
            color: #ffffff;
            text-align: center;
            line-height: 26px;
            margin-left: auto;
            font-size: 12px;
          }

          > .button2 {
            width: 66px;
            height: 25px;
            border: 1px solid #8f8f8f;
            border-radius: 5px;
            color: #8f8f8f;
            text-align: center;
            line-height: 26px;
            margin-left: auto;
            font-size: 12px;
          }
        }

        > .address {
          font-size: 16px;
          color: #8f8f8f;
          margin-top: 9px;
        }
      }

      > img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin-right: 12px;
        cursor: pointer;
      }
    }

    > .line {
      min-height: 88px;
    }

    > .subTitle {
      //    font-size: 24px;
      //    color: #333333;
      //    margin: 10px 0;
      //    line-height: 44px;
      //    // height: 44px;
      // max-height: 150px;
      // // min-height:44px;
      // height: auto;
      //    box-sizing: border-box;
      //    overflow: hidden;
      //    /*必须结合的属性,当内容溢出元素框时发生的事情*/
      //    text-overflow: ellipsis;
      //    /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
      //    display: -webkit-box;
      //    /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
      //    -webkit-line-clamp: 4;
      //    /*用来限制在一个块元素显示的文本的行数。*/
      //    -webkit-box-orient: vertical;
      font-size: 19px;
      color: #333333;
      margin-bottom: 10px;
      line-height: 38px;
      max-height: 150px;
      // min-height:44px;
      height: auto;
      box-sizing: border-box;
      word-break: break-all;
      overflow: hidden;
      /*必须结合的属性,当内容溢出元素框时发生的事情*/
      text-overflow: ellipsis;
      /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
      display: -webkit-box;
      /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
      -webkit-line-clamp: 4;
      /*用来限制在一个块元素显示的文本的行数。*/
      -webkit-box-orient: vertical;
      /deep/ img {
        max-width: 100% !important;
        height: 300px !important;
      }
    }
  }

  .data .colse-collect {
    display: none;
    float: right;
  }

  > .group-one-right {
    cursor: pointer;
    flex: 1;
    border: none;
    margin-bottom: 0;

    > .data {
      font-size: 18px;
      color: #8f8f8f;
      margin-top: auto;

      > span {
        margin-right: 16px;
      }
    }
  }
}

.group-two {
  width: 890px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #8f8f8f;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 30px;

  &:hover .bottom .group-two-div .group-two-bottom .colse-collect {
    display: inline;
  }

  > .bottom {
    display: flex;
    margin-top: 15px;
    width: 890px;

    > .banner3 {
      width: 145px;
      height: 100px;
      margin-right: 20px;
      position: relative;

      img {
        width: 145px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
      }

      .banner3-icon {
        position: absolute;
        width: 50px;
        height: 26px;
        opacity: 0.3;
        background: #000000;
        border-radius: 13px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 26px;
        bottom: 10px;
        right: 10px;
      }
    }

    > .group-two-div {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      > .title {
        font-size: 24px;
        color: #333333;
        margin-bottom: 10px;
      }

      > .subTitle {
        // line-height: 1.8;
        // overflow: hidden;
        // display: block;
        // width: 100%;
        // /*必须结合的属性,当内容溢出元素框时发生的事情*/
        // text-overflow: ellipsis;
        // /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
        // display: -webkit-box;
        // /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
        // -webkit-line-clamp: 2;
        // /*用来限制在一个块元素显示的文本的行数。*/
        // -webkit-box-orient: vertical;

        font-size: 19px;
        color: #333333;
        margin-bottom: 10px;
        line-height: 38px;
        max-height: 150px;
        // min-height:44px;
        height: auto;
        box-sizing: border-box;
        word-break: break-all;
        overflow: hidden;
        /*必须结合的属性,当内容溢出元素框时发生的事情*/
        text-overflow: ellipsis;
        /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
        display: -webkit-box;
        /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
        -webkit-line-clamp: 4;
        /*用来限制在一个块元素显示的文本的行数。*/
        -webkit-box-orient: vertical;

        /deep/ img {
          max-width: 100% !important;
          height: 300px !important;
        }
      }

      > .group-two-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        color: #8f8f8f;
        margin-top: auto;

        > img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 10px;
        }

        > span {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }

        .colse-collect {
          margin-left: auto;
          display: none;
        }

        > .button {
          font-size: 14px;
          color: $--themeColor;
          width: 89px;
          height: 40px;
          background: #dbfbf8;
          border-radius: 5px;
          text-align: center;
          line-height: 40px;
          margin-left: auto;
          font-size: 12px;
        }
      }
    }
  }

  > .title {
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
  }

  > .subTitle {
    // line-height: 1.8;
    // overflow: hidden;
    // /*必须结合的属性,当内容溢出元素框时发生的事情*/
    // text-overflow: ellipsis;
    // /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    // display: -webkit-box;
    // /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
    // -webkit-line-clamp: 2;
    // /*用来限制在一个块元素显示的文本的行数。*/
    // -webkit-box-orient: vertical;

    font-size: 19px;
    color: #333333;
    margin-bottom: 10px;
    line-height: 38px;
    max-height: 150px;
    // min-height:44px;
    height: auto;
    box-sizing: border-box;
    word-break: break-all;
    overflow: hidden;
    /*必须结合的属性,当内容溢出元素框时发生的事情*/
    text-overflow: ellipsis;
    /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    display: -webkit-box;
    /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
    -webkit-line-clamp: 4;
    /*用来限制在一个块元素显示的文本的行数。*/
    -webkit-box-orient: vertical;
    /deep/ img {
      max-width: 100% !important;
      height: 300px !important;
    }
  }

  > .group-two-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: #8f8f8f;
    margin-top: 15px;

    > img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin-right: 10px;
    }

    > span {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    > .button {
      font-size: 12px;
      color: $--themeColor;
      width: 89px;
      height: 40px;
      background: #dbfbf8;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      margin-left: auto;
    }
  }
}

.group-one-right2 {
  width: 890px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 30px;
  cursor: pointer;

  &:hover .data .colse-collect {
    display: inline;
  }

  > .subTitle2 {
    font-size: 18px;
    color: #8f8f8f;
    line-height: 1.8;
    overflow: hidden;
    /*必须结合的属性,当内容溢出元素框时发生的事情*/
    text-overflow: ellipsis;
    /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    display: -webkit-box;
    /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
    -webkit-line-clamp: 2;
    /*用来限制在一个块元素显示的文本的行数。*/
    -webkit-box-orient: vertical;
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #8f8f8f;
  font-size: 18px;

  > .group-one-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &:hover .colse-collect {
      display: inline;
    }

    > .group-one-top-right {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .one {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .title {
          font-size: 24px;
          color: #333333;
        }

        > .position {
          font-size: 13px;
          color: $--themeColor;
          padding: 0 13px;
          box-sizing: border-box;
          height: 25px;
          border: 1px solid $--themeColor;
          border-radius: 6px;
          text-align: center;
          line-height: 25px;
          margin-left: 12px;
        }

        > .button {
          position: absolute;
          right: 0;
          width: 56px;
          height: 25px;
          background: $--themeColor;
          border-radius: 5px;
          color: #ffffff;
          text-align: center;
          line-height: 26px;
          margin-left: auto;
          font-size: 12px;
        }

        > .button2 {
          width: 66px;
          height: 25px;
          border: 1px solid #8f8f8f;
          border-radius: 5px;
          color: #8f8f8f;
          text-align: center;
          line-height: 26px;
          margin-left: auto;
          font-size: 12px;
        }
      }

      > .address {
        font-size: 16px;
        color: #8f8f8f;
        margin-top: 9px;
      }
    }
  }

  > .subTitle {
    // font-size: 24px;
    // color: #333333;
    // margin: 10px 0;
    // line-height: 1.8;
    // overflow: hidden;
    // /*必须结合的属性,当内容溢出元素框时发生的事情*/
    // text-overflow: ellipsis;
    // /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    // display: -webkit-box;
    // /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
    // -webkit-line-clamp: 2;
    // /*用来限制在一个块元素显示的文本的行数。*/
    // -webkit-box-orient: vertical;
    font-size: 19px;
    color: #333333;
    margin-bottom: 10px;
    line-height: 38px;
    max-height: 150px;
    // min-height:44px;
    height: auto;
    box-sizing: border-box;
    word-break: break-all;
    overflow: hidden;
    /*必须结合的属性,当内容溢出元素框时发生的事情*/
    text-overflow: ellipsis;
    /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
    display: -webkit-box;
    /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
    -webkit-line-clamp: 4;
    /*用来限制在一个块元素显示的文本的行数。*/
    -webkit-box-orient: vertical;

    /deep/ img {
      max-width: 100% !important;
      height: 300px !important;
    }
  }

  > .data {
    font-size: 18px;
    color: #8f8f8f;
    margin-top: auto;

    > span {
      margin-right: 16px;
    }

    .colse-collect {
      display: none;
      float: right;
    }
  }

  > .group {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 10px;
  }

  > .group2 {
    margin-bottom: 20px;
  }
}
</style>
