<template>
  <div class="myComment">
    <header>我的评论</header>
    <div class="content" v-loading="loading">
      <div v-if="!loading && !collect.length" class="null-box">
        <img
          :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
          alt=""
          srcset=""
        />
        <div class="title">您还没有评论哦</div>
      </div>
      <list
        v-for="item in collect"
        :key="item.id"
        :id="item.id"
        :myType="2"
        @updateItem="deleteItem"
        :item="item"
        :platform="item.platform"
      ></list>
      <div class="paging">
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="per_page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@components/common/list2";
import { mapState } from "vuex";
export default {
  components: {
    list,
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  data() {
    return {
      loading: false,
      collect: [],
      total: 0,
      per_page: 10,
      page: 1,
    };
  },
  activated() {
    this.collect = [];
    this.postPersonalCollect();
  },
  methods: {
    // 关注过滤
    doctorListFn(e) {
      for (let i in this.collect) {
        if (e.doctor.id == this.collect[i].data.doctor.id) {
          this.collect[i].data.attention = !this.collect[i].data.attention;
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.postPersonalCollect();
    },
    deleteItem(val) {
      let index = this.collect.findIndex((v) => v.id == val.id);
      if (index > -1) {
        this.collect.splice(index, 1);
      }
    },
    async postPersonalCollect() {
      this.loading = true;
      let { code, data,message } = await this.$userApi.postPersonalCollect({
        type: 2,
        page: this.page,
      });
      this.loading = false;
      if (code == 200) {
        data.data.forEach((v) => {
          // if(v.images)v.images = JSON.parse(v.images);
          // else v.images = [];
          if (!v.data.new_type)
            if (v.data.images) v.data.images = JSON.parse(v.data.images);
            else v.data.images = [];
          else {
            let datas = v.data;
            v.data.images = [v.data.images];
            let user = this.userInfo;
            user.info ? "" : (user.info = {});
            v.data.doctor = {
              id: user.info.user_id,
              user_id: user.info.user_id,
              photo: user.avatar,
              name: user.nickname,
              company: user.company.company,
              job_name: user.company.job_name,
            };
          }
        });
        this.collect = data.data;
        this.total = data.total;
        this.per_page = data.per_page;
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myComment {
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
  }
  > .content {
    min-height: 400px;
    box-sizing: border-box;
    // padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
  }
  /deep/.group-one-right2 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
